<template>
  <v-container fluid class="px-8">
    <!-- Table -->
    <v-form v-model="isValid">
      <v-data-table
        ref="table"
        v-model="selected"
        :headers="headers"
        :items="rows"
        :loading="isLoading"
        :items-per-page.sync="itemsPerPage"
        :footer-props="{ 'items-per-page-options': [10, 20, 50, -1] }"
        class="elevation-1"
        sort-by="date"
        @input="itemSelected($event)"
        @toggle-select-all="allSelected($event)">
        <template v-slot:top>
          <!-- Header -->
          <v-toolbar dark color="primary" class="mb-1">
            <v-row class="d-flex align-center justify-space-between" no-gutters>
              <h2>New Hope Podcast Uploader</h2>
            </v-row>
          </v-toolbar>
        </template>
        <!-- Body -->

        <!-- Date -->
        <template v-slot:[`item.date`]="{ item }">
          <v-row class="mx-0 mt-1">
            <v-btn :href="item.youtube" color="secondary" text>{{ item.date }}</v-btn>
          </v-row>
        </template>
        <!-- Title -->
        <template v-slot:[`item.title`]="{ item, header, index }">
          <v-row class="mx-0 mt-1">
            <core-textarea-table
              v-model="item.title"
              :loading="header.updating[index]"
              :hint="header.updating[index] ? 'Updating' : ''"
              @change="updateRow(item, header, index)"></core-textarea-table>
          </v-row>
        </template>

        <!-- Title -->
        <template v-slot:[`item.description`]="{ item, header, index }">
          <v-row class="mx-0 mt-1">
            <core-textarea-table
              v-model="item.description"
              :loading="header.updating[index]"
              :hint="header.updating[index] ? 'Updating' : ''"
              @change="updateRow(item, header, index)"></core-textarea-table>
          </v-row>
        </template>

        <!-- Pastor -->
        <template v-slot:[`item.pastor`]="{ item, header, index }">
          <v-row class="mx-0 mt-2 pb-1">
            <core-select-table
              v-model="item.pastor"
              :items="pastors"
              :loading="header.updating[index]"
              :hint="header.updating[index] ? 'Updating' : ''"
              item-value="id"
              item-text="name"
              filled
              @change="updateRow(item, header, index)">
            </core-select-table>
          </v-row>
        </template>

        <!-- Series -->
        <template v-slot:[`item.series`]="{ item, header, index }">
          <v-row class="mx-0 mt-2 pb-1">
            <core-select-table
              v-model="item.series"
              :items="series"
              :loading="header.updating[index]"
              :hint="header.updating[index] ? 'Updating' : ''"
              filled
              item-text="title"
              item-value="id"
              @input="updateRow(item, header, index)">
            </core-select-table>
          </v-row>
        </template>

        <!-- Track Number -->
        <template v-slot:[`item.track_number`]="{ item, header, index }">
          <v-row class="mx-0 mt-1">
            <core-number-field-table
              v-model="item.track_number"
              :loading="header.updating[index]"
              :hint="header.updating[index] ? 'Updating' : ''"
              @change="updateRow(item, header, index)"></core-number-field-table>
          </v-row>
        </template>

        <!-- Start Time -->
        <template v-slot:[`item.start_time`]="{ item, header, index }">
          <v-row class="mx-0 mt-1">
            <core-number-field-table
              v-model="item.start_time"
              :loading="header.updating[index]"
              :hint="header.updating[index] ? 'Updating' : ''"
              @change="updateRow(item, header, index)"></core-number-field-table>
          </v-row>
        </template>
        <!-- End Time -->
        <template v-slot:[`item.end_time`]="{ item, header, index }">
          <v-row class="mx-0 mt-1">
            <core-number-field-table
              v-model="item.end_time"
              :loading="header.updating[index]"
              :hint="header.updating[index] ? 'Updating' : ''"
              @change="updateRow(item, header, index)"></core-number-field-table>
          </v-row>
        </template>
        <!-- Download -->
        <template v-slot:[`item.download`]="{ item }">
          <v-row class="mx-0">
            <v-btn :loading="isLoading" color="primary" outlined small text @click="download(item)">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-row>
          <v-row class="mx-0 pt-2" v-if="item.audio != null">
            <v-btn color="secondary" outlined small text :href="item.audio">
              <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
          </v-row>
        </template>
        <!-- Upload -->
        <template v-slot:[`item.upload`]="{ item }">
          <v-row class="mx-0">
            <v-btn
              :loading="isLoading"
              :disabled="item.audio == null"
              color="primary"
              outlined
              small
              text
              @click="upload(item)">
              <v-icon>mdi-upload</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-table>
      <v-row class="mt-1">
        <v-col>
          <core-btn-primary :loading="isLoading" @click="getLatestVideo()"
            >Get Latest Video</core-btn-primary
          >
        </v-col>
      </v-row>
      <v-row class="mt-1">
        <v-col cols="2">
          <core-number-field label="Minutes" filled v-model="minutes"></core-number-field>
        </v-col>
        <v-col cols="2">
          <core-number-field label="Seconds" filled v-model="seconds"></core-number-field>
        </v-col>
        <v-col cols="2">
          <core-number-field
            readonly
            label="Total Seconds"
            :value="totalSeconds"></core-number-field>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      isLoading: true,
      isSaving: false,
      isValid: true,
      itemsPerPage: 10,
      headers: [
        { text: "Date", value: "date", updating: [] },
        { text: "Title", value: "title", width: "200", updating: [] },
        { text: "Series", value: "series", updating: [] },
        { text: "Track Number", value: "track_number", updating: [] },
        { text: "Messenger", value: "pastor", updating: [] },
        { text: "Description", value: "description", width: "200", updating: [] },
        { text: "Start (seconds)", value: "start_time", updating: [] },
        { text: "End (seconds)", value: "end_time", updating: [] },
        { text: "Download", value: "download" },
        { text: "Upload", value: "upload" },
      ],
      rows: [],
      series: [],
      pastors: [],

      selected: [],
      selectedGroup: "",
      minutes: 0,
      seconds: 0,
    }
  },
  computed: {
    totalSeconds() {
      return 60 * parseInt(this.minutes) + parseInt(this.seconds)
    },
  },
  methods: {
    async addAccount() {
      if (this.$refs.addAccountForm.validate()) {
        this.isSaving = true

        let payload = {
          account: this.newAccount.account,
          subaccount: this.newAccount.subaccount,
          group_id: this.group.id,
        }

        await this.$django_api.post("purchasing/kfs_account/", payload).then((response) => {
          // Add to end of table on current page
          this.accounts.splice(this.itemsPerPage, 0, response.data)
          this.itemsPerPage += 1
        })

        this.newAccount = { account: "", subaccount: "" }
        this.accountDialog = false
      }

      this.isSaving = false
    },
    async addMember() {
      this.isSaving = true

      let payload = {
        group: this.managerMembership.group,
        user_id: this.newMemberId,
      }
      await this.$django_api.post("core/membership/", payload).then((response) => {
        // Add to end of table on current page
        this.members.splice(this.itemsPerPage, 0, response.data)
        this.itemsPerPage += 1
      })

      this.isSaving = false
      this.memberDialog = false
    },

    async updateRow(rowData, header, rowIndex, event) {
      let endpoint = "new_hope/podcast_episode/"

      if (this.isValid) {
        header.updating[rowIndex] = true
        this.$forceUpdate()

        await this.$django_api
          .put(endpoint, rowData)
          .then((response) => {
            Object.assign(rowData, response.data)
          })
          .catch((error) => {
            Object.assign(rowData, error.response.data.original)
          })

        setTimeout(() => {
          header.updating[rowIndex] = false
          this.$forceUpdate()
        }, 1000)
      }
    },

    async download(episode) {
      this.isLoading = true

      await this.$django_api
        .get("new_hope/youtube_download?id=" + episode.id, { timeout: 240000 })
        .then((response) => {
          episode.audio = response.data.audio

          let alert = {
            "type": "success",
            "message": "Audio processed. Please verify audio quality before uploading.",
          }
          this.$store.dispatch("set_alert", alert)
        })
        .catch((error) => {
          this.isLoading = false
        })

      this.isLoading = false
    },
    async getLatestVideo(episode) {
      this.isLoading = true

      await this.$django_api.get("new_hope/get_latest_youtube").then((response) => {
        let alert = {
          "type": "success",
        }

        if (response.data.created) {
          this.rows.push(response.data.episode)
          alert.message = "Synced with Youtube. Grabbed message from most recent Sunday."
        } else {
          alert.message = "Synced with Youtube. No new episodes to grab."
        }

        this.$store.dispatch("set_alert", alert)
      })

      this.isLoading = false
    },
  },
  mounted: async function () {
    this.isLoading = true

    await this.$django_api.get("new_hope/pastor").then((response) => {
      this.pastors = response.data
    })
    await this.$django_api.get("new_hope/series").then((response) => {
      this.series = response.data
    })

    await this.$django_api.get("new_hope/podcast_episode").then((response) => {
      this.rows = response.data
      this.isLoading = false
    })
  },
  watch: {},
}
</script>
