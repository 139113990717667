var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-8",attrs:{"fluid":""}},[_c('v-form',{model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-data-table',{ref:"table",staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.rows,"loading":_vm.isLoading,"items-per-page":_vm.itemsPerPage,"footer-props":{ 'items-per-page-options': [10, 20, 50, -1] },"sort-by":"date"},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"input":function($event){return _vm.itemSelected($event)},"toggle-select-all":function($event){return _vm.allSelected($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-1",attrs:{"dark":"","color":"primary"}},[_c('v-row',{staticClass:"d-flex align-center justify-space-between",attrs:{"no-gutters":""}},[_c('h2',[_vm._v("New Hope Podcast Uploader")])])],1)]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"mx-0 mt-1"},[_c('v-btn',{attrs:{"href":item.youtube,"color":"secondary","text":""}},[_vm._v(_vm._s(item.date))])],1)]}},{key:"item.title",fn:function(ref){
var item = ref.item;
var header = ref.header;
var index = ref.index;
return [_c('v-row',{staticClass:"mx-0 mt-1"},[_c('core-textarea-table',{attrs:{"loading":header.updating[index],"hint":header.updating[index] ? 'Updating' : ''},on:{"change":function($event){return _vm.updateRow(item, header, index)}},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}})],1)]}},{key:"item.description",fn:function(ref){
var item = ref.item;
var header = ref.header;
var index = ref.index;
return [_c('v-row',{staticClass:"mx-0 mt-1"},[_c('core-textarea-table',{attrs:{"loading":header.updating[index],"hint":header.updating[index] ? 'Updating' : ''},on:{"change":function($event){return _vm.updateRow(item, header, index)}},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})],1)]}},{key:"item.pastor",fn:function(ref){
var item = ref.item;
var header = ref.header;
var index = ref.index;
return [_c('v-row',{staticClass:"mx-0 mt-2 pb-1"},[_c('core-select-table',{attrs:{"items":_vm.pastors,"loading":header.updating[index],"hint":header.updating[index] ? 'Updating' : '',"item-value":"id","item-text":"name","filled":""},on:{"change":function($event){return _vm.updateRow(item, header, index)}},model:{value:(item.pastor),callback:function ($$v) {_vm.$set(item, "pastor", $$v)},expression:"item.pastor"}})],1)]}},{key:"item.series",fn:function(ref){
var item = ref.item;
var header = ref.header;
var index = ref.index;
return [_c('v-row',{staticClass:"mx-0 mt-2 pb-1"},[_c('core-select-table',{attrs:{"items":_vm.series,"loading":header.updating[index],"hint":header.updating[index] ? 'Updating' : '',"filled":"","item-text":"title","item-value":"id"},on:{"input":function($event){return _vm.updateRow(item, header, index)}},model:{value:(item.series),callback:function ($$v) {_vm.$set(item, "series", $$v)},expression:"item.series"}})],1)]}},{key:"item.track_number",fn:function(ref){
var item = ref.item;
var header = ref.header;
var index = ref.index;
return [_c('v-row',{staticClass:"mx-0 mt-1"},[_c('core-number-field-table',{attrs:{"loading":header.updating[index],"hint":header.updating[index] ? 'Updating' : ''},on:{"change":function($event){return _vm.updateRow(item, header, index)}},model:{value:(item.track_number),callback:function ($$v) {_vm.$set(item, "track_number", $$v)},expression:"item.track_number"}})],1)]}},{key:"item.start_time",fn:function(ref){
var item = ref.item;
var header = ref.header;
var index = ref.index;
return [_c('v-row',{staticClass:"mx-0 mt-1"},[_c('core-number-field-table',{attrs:{"loading":header.updating[index],"hint":header.updating[index] ? 'Updating' : ''},on:{"change":function($event){return _vm.updateRow(item, header, index)}},model:{value:(item.start_time),callback:function ($$v) {_vm.$set(item, "start_time", $$v)},expression:"item.start_time"}})],1)]}},{key:"item.end_time",fn:function(ref){
var item = ref.item;
var header = ref.header;
var index = ref.index;
return [_c('v-row',{staticClass:"mx-0 mt-1"},[_c('core-number-field-table',{attrs:{"loading":header.updating[index],"hint":header.updating[index] ? 'Updating' : ''},on:{"change":function($event){return _vm.updateRow(item, header, index)}},model:{value:(item.end_time),callback:function ($$v) {_vm.$set(item, "end_time", $$v)},expression:"item.end_time"}})],1)]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"mx-0"},[_c('v-btn',{attrs:{"loading":_vm.isLoading,"color":"primary","outlined":"","small":"","text":""},on:{"click":function($event){return _vm.download(item)}}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1),(item.audio != null)?_c('v-row',{staticClass:"mx-0 pt-2"},[_c('v-btn',{attrs:{"color":"secondary","outlined":"","small":"","text":"","href":item.audio}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)],1):_vm._e()]}},{key:"item.upload",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"mx-0"},[_c('v-btn',{attrs:{"loading":_vm.isLoading,"disabled":item.audio == null,"color":"primary","outlined":"","small":"","text":""},on:{"click":function($event){return _vm.upload(item)}}},[_c('v-icon',[_vm._v("mdi-upload")])],1)],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-row',{staticClass:"mt-1"},[_c('v-col',[_c('core-btn-primary',{attrs:{"loading":_vm.isLoading},on:{"click":function($event){return _vm.getLatestVideo()}}},[_vm._v("Get Latest Video")])],1)],1),_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"2"}},[_c('core-number-field',{attrs:{"label":"Minutes","filled":""},model:{value:(_vm.minutes),callback:function ($$v) {_vm.minutes=$$v},expression:"minutes"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('core-number-field',{attrs:{"label":"Seconds","filled":""},model:{value:(_vm.seconds),callback:function ($$v) {_vm.seconds=$$v},expression:"seconds"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('core-number-field',{attrs:{"readonly":"","label":"Total Seconds","value":_vm.totalSeconds}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }